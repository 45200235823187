





















import Vue, {PropOptions} from 'vue';
import {UiKernelParameter} from '@roomle/web-sdk/lib/definitions/typings/kernel';
import {iconByKey} from '@/common/utils/icons';
import {Nullable} from '@/common/utils/types';
import {getFieldCustomConfigByKey} from '@/common/utils/custom-config';
import SvgIcon from '@/configurator/components/utils/SvgIcon.vue';

export default Vue.extend({
  components: {
    SvgIcon,
  },

  props: {
    parameter: Object as PropOptions<UiKernelParameter>,
  },

  data() {
    return {
      config: getFieldCustomConfigByKey(this.parameter.key),
    };
  },

  methods: {
    async setValue(value: string) {
      await this.$sdkConnector.setParameter(this.parameter, value);
    },
    formatLabel(label: string): string {
      const split = label.split(' = ');
      return split.length > 1 ? `${split[0]}<br>(${split[1]})` : split[0];
    },
    icon(valueKey: string): Nullable<string> {
      console.log((this.config.svg_prefix ? this.config.svg_prefix : '') + valueKey.replace(' right', '').replace(' left', ''));
      return iconByKey((this.config.svg_prefix ? this.config.svg_prefix : '') + valueKey.replace(' right', '').replace(' left', ''));
    },
  },
});
