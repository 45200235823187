























import Vue, {PropOptions} from 'vue';
import {UiKernelParameter} from '@roomle/web-sdk/lib/definitions/typings/kernel';
import {getFieldCustomConfigByKey} from '@/common/utils/custom-config';
import SvgIcon from '@/configurator/components/utils/SvgIcon.vue';
import SvgCheck from '@/common/svgs/check.svg';

export default Vue.extend({
  components: {
    SvgIcon,
  },

  props: {
    parameter: Object as PropOptions<UiKernelParameter>,
  },

  data() {
    return {
      config: getFieldCustomConfigByKey(this.parameter.key),
    };
  },

  computed: {
    icons(): object {
      return {
        SvgCheck,
      };
    },
  },

  methods: {
    async setValue(value: string) {
      await this.$sdkConnector.setParameter(this.parameter, value);
    },
    formatLabel(label: string): string {
      const split = label.split(' = ');
      return split.length > 1 ? `${split[0]}<br>(${split[1]})` : split[0];
    },
    getImage(value: string): ImageBitmapSource {
      const path = (this.config.image_prefix ? this.config.image_prefix : '') + value.replace(/( |\/)/g, '_').toUpperCase();
      let image;
      try {
        image = require('@/common/images/' + path + '.png');
      } catch (e) {
        console.log(path);
        try {
          image = require('@/common/images/' + path + '.jpg');
        } catch (e) {
          try {
            image = require('@/common/images/' + path + '.jpeg');
          } catch (e) {
            image = require('@/common/images/fallback.png');
          }
        }
      }
      return image;
    },
  },
});
