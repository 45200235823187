































































































import Vue, {PropOptions} from 'vue';
import {UiKernelParameter} from '@roomle/web-sdk/lib/definitions/typings/kernel';
import {RapiMaterialGroup} from '@roomle/web-sdk/lib/definitions/typings/rapi-types';
import {getAllMaterials, RapiMaterialEmbeddedTextures} from '@/common/utils/parameters';
import GridView from '@/configurator/components/grid-view/GridView.vue';
import {GridViewElement, GridViewGroup, toGridViewElements} from '@/configurator/components/grid-view/-utils/GridViewHelper';
import {uuid} from '@/common/utils/helper';

// @ts-ignore
// import styles because getComputedStyle is very expensive
// ts-ignore because there is a bug with css-modules in vue cli and typescript
import {allItemsInPreview, allItemsInPreviewDesktop} from '@/configurator/components/parameters/types/-utils/ParameterValueElement.module.scss';

import {CssHash} from '@/common/utils/types';
import {getImageURL} from '@/common/utils/helper';
import SvgIcon from '@/configurator/components/utils/SvgIcon.vue';
import SvgCheck from '@/common/svgs/check.svg';
import SvgPlus from '@/common/svgs/custom/dist/common/plus.svg';
import SvgMinus from '@/common/svgs/custom/dist/common/minus.svg';
import SvgTooltip from '@/common/svgs/custom/dist/common/tooltip.svg';
import SvgTooltipPlus from '@/common/svgs/custom/dist/common/tooltip-plus.svg';
import {sort} from '@/common/utils/sort-materials';
import {UI_STATE_MUTATIONS} from '@/configurator/store/ui-state';
import {OVERLAYS} from '../../-utils/overlays';


export default Vue.extend({

  components: {
    GridView,
    SvgIcon,
  },

  props: {
    parameter: Object as PropOptions<UiKernelParameter>,
    isExpanded: Boolean,
    onExpand: Function,
    onCollapse: Function,
  },

  data() {
    return {
      groups: [] as RapiMaterialGroup[],
      showMore: false,
    };
  },

  watch: {
    parameter: {
      immediate: true,
      async handler() {
        try {
          this.groups = await this.parameter.groups as RapiMaterialGroup[];
        } catch (e) {
          console.warn(e);
        }
      },
    },
  },

  computed: {
    allElements(): GridViewElement[] {
      const groups = this.groups || [];
      if (!groups.length) {
        const validValues = this.parameter.validValues;

        const elementsInPreview = this.$store.state.uiState.isDesktop ? allItemsInPreviewDesktop : allItemsInPreview;
        // need a fallback for legacy data like usm, because usm has no valid values only valid groups
        const validValuesLength = validValues.length || Math.min(elementsInPreview, 3);
        const maxValue = Math.min(validValuesLength, elementsInPreview);
        const fakeMaterials: GridViewElement[] = [];
        for (let i = 0; i < maxValue; i++) {
          fakeMaterials.push({
            key: uuid(),
            payload: {
              shading: {
                basecolor: {
                  r: 255,
                  g: 255,
                  b: 255,
                },
              },
              label: '',
            } as any,
          });
        }
        return fakeMaterials;
      }
      return toGridViewElements(getAllMaterials(groups), 'id');
    },
    selectedElement(): GridViewElement {
      return this.allElements.find((el) => (el.payload as RapiMaterialEmbeddedTextures).id === this.parameter.value) as GridViewElement;
    },
    normalizedGroups(): GridViewGroup[] {
      const groups = this.groups || [];
      const normalizedGroups = groups.map(({id, label, materials}) => ({
        id,
        label,
        elements: toGridViewElements(materials, 'id'),
      }));
      
      return sort(normalizedGroups);
    },
    icons(): object {
      return {
        SvgCheck,
        SvgPlus,
        SvgMinus,
        SvgTooltip,
        SvgTooltipPlus,
      };
    },
  },

  methods: {
    async onSelect({key}: {key: string}) {
      await this.$sdkConnector.setParameter(this.parameter, key);
    },
    useImage(material: any) {
      if (material.payload) {
        return !!material.payload.shading;
      }
      return false;
    },
    getMaterialThumbnail(material: RapiMaterialEmbeddedTextures): CssHash {
      const css: CssHash = {};
      let rgb = null;
      if (material.color) {
        rgb = material.color;
      }
      if (material.shading && material.shading.basecolor) {
        rgb = [
          Math.round(material.shading.basecolor.r * 255),
          Math.round(material.shading.basecolor.g * 255),
          Math.round(material.shading.basecolor.b * 255),
        ].join(',');
      }
      if (rgb) {
        css['background-color'] = 'rgb(' + rgb + ')';
      }
      let backgroundImage = null;
      if (material.thumbnail) {
        backgroundImage = getImageURL(material.thumbnail, 64, 32);
      }
      if (!rgb && !material.thumbnail && material.embeddedTextures && material.embeddedTextures.length > 0) {
        console.warn('Material has no color, no thumbnail, fallback to texture!');
      }
      if (backgroundImage) {
        css['background-image'] = 'url(' + backgroundImage + ')';
      }
      return css;
    },
    formatLabel(label: string): string {
      if (this.$store.state.uiState.initData.customConfig.general.headline === 'Lax Floating Duo' || this.$store.state.uiState.initData.customConfig.general.headline === 'Lax Meets Floor') {
        if (this.$store.state.uiState.selectedGroup.key === 'grpBasin') {
          switch (label.toUpperCase()) {
            case 'FENIX = NTM':
              label = `Nano (${label} = )`;
              break;
            case 'FEINSTEINZEUG':
            case 'PORCELAIN STONEWARE':
              label = `Litho (${label})`;
              break;
            case 'VELVET STONE = TM':
              label = `Murale (${label} = )`;
              break;
            case 'CORIAN':
              label = `Qoro (${label})`;
              break;
            default:
              break;
          }
        }
      }
      const split = label.split(' = ');
      return split.length > 1 ? `${split[0]}<sup>${split[1]}</sup>${split[2] ? split[2] : ''}` : split[0];
    },
    getItemTooltip(groupId: string): string|null {
      if (this.$attrs.materialTooltips && groupId in Object(this.$attrs.materialTooltips) && !Object(this.$attrs.materialTooltips)[groupId]?.help_active) {
        // @ts-ignore
        return this.$attrs.materialTooltips[groupId];
      } else {
        return null;
      }
    },
    getHelp(groupId: string): any|null {
      if (this.$attrs.materialTooltips && groupId in Object(this.$attrs.materialTooltips) && Object(this.$attrs.materialTooltips)[groupId]?.help_active) {
        return {
          target: Object(this.$attrs.materialTooltips)[groupId].help_target || null,
          pretitle: Object(this.$attrs.materialTooltips)[groupId].help_pretitle || null,
          title: Object(this.$attrs.materialTooltips)[groupId].help_title || null,
          subtitle: Object(this.$attrs.materialTooltips)[groupId].help_subtitle || null,
          content: Object(this.$attrs.materialTooltips)[groupId].help_content || null,
        };
      } else {
        return null;
      }
    },
    help(groupId: string) {
      this.$store.commit(UI_STATE_MUTATIONS.SET_OVERLAY_STATE, {overlay: OVERLAYS.HELP, open: true, payload: this.getHelp(groupId)});
    },
  },

});

