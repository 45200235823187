





























































































































































































































































































































































































































































import Vue from 'vue';

import ARButton from '@/configurator/components/top-bar/ARButton.vue';
// @ts-ignore
import SvgDots from '@/common/svgs/menu-points.svg';
// @ts-ignore
import SvgPartList from '@/common/svgs/partlist.svg';
// @ts-ignore
import SvgMultiSelect from '@/common/svgs/mulitselect.svg';
// @ts-ignore
import SvgMeasurements from '@/common/svgs/measurements.svg';
// @ts-ignore
import SvgCamera from '@/common/svgs/camera.svg';
// @ts-ignore
import SvgZoom from '@/common/svgs/zoom.svg';
// @ts-ignore
import SvgExport3d from '@/common/svgs/export-3d.svg';
// @ts-ignore
import SvgScreenshot from '@/common/svgs/screenshot.svg';
// @ts-ignore
import SvgToggleDrawers from '@/common/svgs/toggle-drawers.svg';
// @ts-ignore
import SvgUndo from '@/common/svgs/undo.svg';
// @ts-ignore
import SvgRedo from '@/common/svgs/redo.svg';
// @ts-ignore
import SvgId from '@/common/svgs/custom/dist/common/id.svg';

import SvgIcon from '@/configurator/components/utils/SvgIcon.vue';

import {BUTTON_ACTIONS, UI_STATE_GETTERS, UI_STATE_MUTATIONS} from '@/configurator/store/ui-state';
import {OVERLAYS} from '@/configurator/components/-utils/overlays';
import {toBoolean} from '@/common/utils/helper';
import {UI_BUTTON} from '@/configurator/embedding/types';

import {getGeneralCustomConfig} from '@/common/utils/custom-config';
import ConfiguratorSelection from '@/configurator/components/ConfiguratorSelection.vue';
import {KernelParameter} from '@roomle/web-sdk/lib/definitions/typings/kernel';
import {API_BASE} from '@/common/utils/api-url';
import RoomleConfigurator from '@roomle/web-sdk/lib/definitions/configurator-core/src/roomle-configurator';
import { CORE_DATA_MUTATIONS } from '../store/core-data';

export default Vue.extend({
  components: {
    SvgIcon,
    ARButton,
    BackToWebsite: () => import('@/configurator/components/utils/BackToWebsite.vue'),
    ConfiguratorSelection,
  },
  computed: {
    icons(): object {
      return {
        SvgDots,
        SvgPartList,
        SvgMultiSelect,
        SvgMeasurements,
        SvgCamera,
        SvgZoom,
        SvgScreenshot,
        SvgExport3d,
        SvgToggleDrawers,
        SvgUndo,
        SvgRedo,
        SvgId,
      };
    },
    areDimensionsVisible(): boolean {
      return this.$store.state.uiState.selectedActions.includes(BUTTON_ACTIONS.DIMENSIONS);
    },
    BUTTON_ACTIONS(): typeof BUTTON_ACTIONS {
      return BUTTON_ACTIONS;
    },
    numberOfSelectedActions(): number {
      return this.$store.state.uiState.selectedActions.length;
    },
    isDesktop(): boolean {
      return this.$store.state.uiState.isDesktop;
    },
    expandedGroups(): boolean {
      const isExpanded = this.$store.state.uiState.interactionsExpanded;
      return this.$store.state.uiState.isDesktop ? !isExpanded : isExpanded;
    },
    showArButton(): boolean {
      return toBoolean(this.$store.state.uiState.initData.buttons?.[UI_BUTTON.AR]);
    },
    showPartlistButton(): boolean {
      return toBoolean(this.$store.state.uiState.initData.buttons?.[UI_BUTTON.PARTLIST]);
    },
    showMultiselectButton(): boolean {
      const shouldShow = toBoolean(this.$store.state.uiState.initData.buttons?.[UI_BUTTON.MULTISELECT]);
      return this.$store.state.coreState.componentHasChildren && shouldShow;
    },
    showDimensionsButton(): boolean {
      return toBoolean(this.$store.state.uiState.initData.buttons?.[UI_BUTTON.DIMENSIONS]);
    },
    showFullscreenButton(): boolean {
      return toBoolean(this.$store.state.uiState.initData.buttons?.[UI_BUTTON.FULLSCREEN]);
    },
    showExport3dButton(): boolean {
      return toBoolean(this.$store.state.uiState.initData.buttons?.[UI_BUTTON.EXPORT_3D]);
    },
    showResetCameraButton(): boolean {
      return toBoolean(this.$store.state.uiState.initData.buttons?.[UI_BUTTON.RESETCAMERA], false);
    },
    showRenderImageButton(): boolean {
      return toBoolean(this.$store.state.uiState.initData.buttons?.[UI_BUTTON.RENDERIMAGE], false);
    },
    showMenuButton(): boolean {
      return this.showPartlistButton || this.showMultiselectButton
        || this.showDimensionsButton || this.showFullscreenButton
        || this.showResetCameraButton || this.showRenderImageButton;
    },
    showBackToWebsite(): boolean {
      return this.$store.getters[UI_STATE_GETTERS.SHOW_BACK_TO_WEBSITE];
    },
    showToggleDrawerButton(): boolean {
      let hasToggleDrawerParameter = false;

      this.$store.state.coreData.groups.forEach((group: any) => {
        group.parameters.forEach((parameter: any) => {
          if (parameter.key.startsWith('elAll_drawersOpen') || parameter.key.startsWith('AllDrawersOpen') || parameter.key.startsWith('el1_drawerOpen') || parameter.key.startsWith('openDrawer')) {
            hasToggleDrawerParameter = true;
          }
        });
      });

      return hasToggleDrawerParameter;
    },
    isDrawerOpen(): boolean {
      let isOpen = false;

      this.$store.state.coreData.groups.forEach((group: any) => {
        group.parameters.forEach((parameter: any) => {
          if (parameter.key.startsWith('elAll_drawersOpen') || parameter.key.startsWith('AllDrawersOpen') || parameter.key.startsWith('el1_drawerOpen') || parameter.key.startsWith('openDrawer')) {
            isOpen = parameter.value === '1.00' || parameter.value === '1';
          }
        });
      });

      return isOpen;
    },
    showUndoButton(): boolean {
      return true;
    },
    showRedoButton(): boolean {
      return true;
    },
    title(): string {
      return getGeneralCustomConfig().headline;
    },
    price(): string {
      let {price} = this.$store.state.coreData;

      price = new Intl.NumberFormat('de-DE', {minimumFractionDigits: 2}).format(price);

      const priceWithCurrency = `${this.$store.state.coreData.currencySymbol} ${price}`;
      return priceWithCurrency;
    },
  },

  methods: {
    _closeDropDown() {
      if (this.$el.querySelector('.checkbox-toggle')) {
        (this.$el.querySelector('.checkbox-toggle') as HTMLInputElement).checked = false;
      }
    },

    toggleMultiselect() {
      if (!this.isDesktop) {
        this._closeDropDown();
      }
      if (this.toggleAction(BUTTON_ACTIONS.MULTISELECT)) {
        this.enableMultiSelect();
      } else {
        this.disableMultiSelect();
      }
      this.$embeddingCallbacks.onButtonClicked(UI_BUTTON.MULTISELECT);
    },
    openFullscreen() {
      if (!this.isDesktop) {
        this._closeDropDown();
      }
      this.$store.commit(UI_STATE_MUTATIONS.SET_IS_FULLSCREEN, true);
      this.$embeddingCallbacks.onButtonClicked(UI_BUTTON.FULLSCREEN);
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
      }, 50);
    },
    getHidePrice(): boolean {
      return getGeneralCustomConfig().hidePrice;
    },
    openExport3D() {
      this.$store.commit(UI_STATE_MUTATIONS.SET_OVERLAY_STATE, {overlay: OVERLAYS.EXPORT_3D, open: true});
      this.$embeddingCallbacks.onButtonClicked(UI_BUTTON.EXPORT_3D);
      this._closeDropDown();
    },
    async toggleDimensions() {
      this.$embeddingCallbacks.onButtonClicked(UI_BUTTON.DIMENSIONS);
      if (!this.isDesktop) {
        this._closeDropDown();
      }
      const showMeasurementsParameter = this.$store.state.coreData.groups
        .filter((group: any) => {
          return group.parameters.filter((param: KernelParameter) => param.key === 'showDimensions')[0];
        })[0].parameters
        .filter((param: KernelParameter) => {
          return param.key === 'showDimensions';
        })[0];
      await this.$sdkConnector.setParameter(showMeasurementsParameter, showMeasurementsParameter.value === '0' ? '1' : '0');
    },
    async enableMultiSelect() {
      const configurator = await this.$sdkConnector.api;
      configurator.enableMultiselect();
    },
    async disableMultiSelect() {
      const configurator = await this.$sdkConnector.api;
      configurator.disableMultiselect();
    },
    isActionActive(action: number) {
      return this.$store.state.uiState.selectedActions.includes(action);
    },
    toggleAction(action: number) {
      if (this.isActionActive(action)) {
        this.$store.commit(UI_STATE_MUTATIONS.SET_ACTION_DESELECTED, action);
        return false;
      } else {
        this.$store.commit(UI_STATE_MUTATIONS.SET_ACTION_SELECTED, action);
        return true;
      }
    },
    openPartList() {
      this.$store.commit(UI_STATE_MUTATIONS.SET_OVERLAY_STATE, {overlay: OVERLAYS.PART_LIST, open: true});
      this.$embeddingCallbacks.onButtonClicked(UI_BUTTON.PARTLIST);
      this._closeDropDown();
    },
    async resetCamera() {
      this.$embeddingCallbacks.onButtonClicked(UI_BUTTON.RESETCAMERA);
      const configurator = await this.$sdkConnector.api;
      configurator.resetCameraPosition();
    },
    async renderImage() {
      this.$embeddingCallbacks.onButtonClicked(UI_BUTTON.RENDERIMAGE);
    },
    async toggleDrawers() {
      // e.currentTarget.classList.toggle('is-active');
      const params: any[] = [];
      let isOpen = false;

      this.$store.state.coreData.groups.forEach((group: any) => {
        group.parameters.forEach((parameter: any) => {
          if (parameter.key.startsWith('elAll_drawersOpen') || parameter.key.startsWith('AllDrawersOpen') || parameter.key.startsWith('el1_drawerOpen') || parameter.key.startsWith('openDrawer')) {
            params.push(parameter);
            isOpen = parameter.value === '1.00' || parameter.value === '1';
          }
        });
      });

      for (const param of params) {
        this.$sdkConnector.setParameter(param, isOpen ? '0.00' : '1.00');
      }

      if (!this.isDesktop) {
        this._closeDropDown();
      }
    },
    async undo() {
      this.$store.commit(CORE_DATA_MUTATIONS.UPDATE_UNDO_REDO_TRIGGER, true);
      const configurator = (await this.$sdkConnector.api as RoomleConfigurator);
      configurator.undo();
    },
    async redo() {
      this.$store.commit(CORE_DATA_MUTATIONS.UPDATE_UNDO_REDO_TRIGGER, true);
      const configurator = (await this.$sdkConnector.api as RoomleConfigurator);
      configurator.redo();
    },
    getLook(): string {
      return getGeneralCustomConfig().look;
    },
    openCollection() {
      // @ts-ignore
      this.$refs.configurationSelection.openCollection();
    },
    openIdModal() {
      window.parent.postMessage(this.getLook() === 'default' ? 'just disable overflow' : 'disable overflow', API_BASE);
      window.parent.postMessage('open id modal', API_BASE);
      this.$store.commit(CORE_DATA_MUTATIONS.UPDATE_IS_EDITING, false);
    },
  },
});
